<template>
	<div>
		<!-- 没有子级 -->
		<template v-if="!menuItem.children || !menuItem.children.length">
			<el-menu-item :index="menuItem.path">{{ menuItem.title }}</el-menu-item>
		</template>
		<!-- 有子级 -->
		<template v-else>
			<el-submenu :index="menuItem.path">
				<template slot="title">
					<span>{{ menuItem.title }}</span>
				</template>
				<SidebarItem v-for="child in menuItem.children" :key="child.name" :menu-item="child"></SidebarItem>
			</el-submenu>
		</template>
	</div>
</template>

<script>
	export default {
		name: 'SidebarItem',
		props: {
			menuItem: Object,
		}
	}
</script>

<style>
</style>
