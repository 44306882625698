import Vue from "vue";
import VueRouter from "vue-router";

import Layout from "@/layout/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("../views/home"),
        meta: {
          title: "主页",
        },
      },
      {
        path: "/goods/index",
        name: "goods",
        component: () => import("../views/goods"),
        meta: {
          title: "商品列表",
        },
      },
      {
        path: "/goods/add",
        name: "Add",
        component: () => import("../views/goods/add"),
        meta: {
          title: "新增",
        },
      },
      {
        path: "/goods/detail",
        name: "Detail",
        component: () => import("../views/goods/detail"),
        meta: {
          title: "详情",
        },
      },
      {
        path: "/order/index",
        name: "Order",
        component: () => import("../views/order"),
        meta: {
          title: "订单列表",
        },
      },
      {
        path: "/order/detail",
        name: "orderDetail",
        component: () => import("../views/order/detail"),
        meta: {
          title: "订单详情",
        },
      },
      {
        path: "/user/index",
        name: "userIndex",
        component: () => import("../views/user/index"),
        meta: {
          title: "用户列表",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index"),
    meta: {
      title: "登录",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  let isLogin = sessionStorage.getItem('isLogin')
  console.log(to.path,!isLogin,'isLogin')
  if(isLogin) {
    next()
  } else if( !isLogin && to.path !== '/login') {
    // next(`/login`)
    console.log(2222222)
    return next("/login");
  } else {
    next()
  }
  // 全局前置守卫逻辑
});
export default router;
