// 菜单数据
export const menuData = [
  {
    path: "/home",
    name: "Home",
    title: "主页",
  },
  {
    path: "/goods/index",
    name: "goodsIndex",
    title: "商品列表",
  },
  {
    path: "/order/index",
    name: "OrderIndex",
    title: "订单列表",
  },
  {
    path: "/user/index",
    name: "OrderIndex",
    title: "用户列表",
  },
  //   {
  //     path: "/product",
  //     name: "goodsProduct",
  //     title: "产品",
  //     children: [
  //       {
  //         path: "/goods/detail",
  //         name: "goodsDetail",
  //         title: "详情",
  //       },
  //       {
  //         path: "/goods/price",
  //         name: "goodsPrice",
  //         title: "价格",
  //       },
  //     ],
  //   },
];
