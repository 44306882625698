import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeRoute: "/home",
  },
  getters: {},
  mutations: {
    setAvtiveRoute(state, payload) {
      state.activeRoute = payload;
    },
  },
  actions: {},
  modules: {},
});
