<template>
  <el-container>
    <el-aside>
      <el-menu
        router
        :default-active="activeRoute"
        text-color="#FFF"
        active-text-color="#00DC82"
        background-color="#202229"
      >
        <SidebarItem
          v-for="menu in menuData"
          :key="menu.name"
          :menu-item="menu"
        ></SidebarItem>
      </el-menu>
    </el-aside>

    <el-container>
      <el-header>
        <div>{{ name }}</div>
        <!-- <el-dropdown>
          <i class="el-icon-setting" style="margin-right: 15px"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>查看</el-dropdown-item>
            <el-dropdown-item>新增</el-dropdown-item>
            <el-dropdown-item>删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span>王小虎</span> -->
      </el-header>

      <el-main>
        <transition name="fade-transform" mode="out-in">
          <keep-alive>
            <router-view :key="activeRoute"></router-view>
          </keep-alive>
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SidebarItem from "./SidebarItem.vue";

import { menuData } from "./menuData.js";

export default {
  components: {
    SidebarItem,
  },
  data() {
    return {
      menuData,
      name: "主页",
    };
  },
  computed: {
    activeRoute() {
      return this.$store.path;
    },
  },
  watch: {
    $route(route) {
      this.name = this.menuData.filter((i) => i.path === route.path)[0]?.title;
      this.$store.commit("setAvtiveRoute", route.path);
    },
  },
};
</script>

<style>
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.3s;
}

/* 离开时减少元素宽度，因为过度效果使用的是translateX，触发时会撑大盒子，导致抖动 */
.fade-transform-leave-active {
  width: 99%;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.el-container {
  height: 100vh;
}

.el-header {
  background: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  width: 230px !important;
  color: #666;
  background: #202229;
}

.el-menu {
  border: 0 !important;
}

.el-header {
  font-size: 14px;
  background: #fff;
  box-shadow: 1px 1px 2px #ccc;
  font-weight: 600;
  z-index: 10;
  text-align: left;
}
</style>
